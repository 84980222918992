<template>
  <div class="router-wrap">
    <div class="router-banner"></div>
    <div class="joinUsDetails-content">
      <div class="tab-item active">{{ title }}</div>
      <div class="tab-content">
        <div class="tab-content-top">
          <img :src="jianjieback" alt="" />
          <span></span>
          <p :style="{ color: langTrue == 'en-us' ? '#e7e7e7' : '#444444' }">
            合作伙伴
          </p>
        </div>
        <!-- <ul class="tab-btns">
          <li
            v-for="(val, ind) in typeList"
            :key="ind"
            :class="{ active: activeTab === val.id }"
            @click="activeTabclick(val.id)"
          >
            {{ val.name }}
          </li>
        </ul> -->
        <ul class="bank-list" v-if="bankList.length !== 0">
          <li v-for="(val, ind) in bankList" :key="ind" @click="tanchuang(val)">
            <p>
              <img :src="val.img" alt="" />
            </p>
            <div :title="val.name">{{ val.name }}</div>
          </li>
        </ul>
        <div v-if="nodata">
          <NoData></NoData>
        </div>
      </div>
      <Back
        :name="langTrue == 'en-us' ? 'Cooperative partner' : '合作伙伴'"
        backtop="27px"
        backright="70px"
        homeBack="/HomePage"
        :homeName="langTrue == 'en-us' ? 'home page' : '首页'"
      />
    </div>

    <Footer></Footer>
    <div class="tanchuang" v-if="istrue == true" @click.self="tanchuangfalse()">
      <div class="tanchuangval">
        <div class="tanchuangvalback" @click="tanchuangfalse()"></div>
        <div class="tanchuangvaltop"></div>
        <div class="tanchuangvaliicon">
          <img class="tanchuangvaliiconimg" :src="tanchuangval.img" alt="" />
        </div>
        <div class="tanchuangvaltitle">{{ tanchuangval.name }}</div>
        <div class="tanchuangvaltvalue">{{ tanchuangval.val }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Back from "@/components/back";
import { selectPartnerList } from "@/api/common.js";
import { baseURL } from "@/api/config.js";
import NoData from "@/components/noData.vue";
export default {
  name: "Partner",
  data() {
    return {
      activeTab: 1,
      istrue: false,
      nodata:false,
      tanchuangval: {},
      typeList: [
        {
          name:
            sessionStorage.getItem("langType") == "en-us"
              ? "Government sector"
              : "政府部门",
          id: 1,
        },
        {
          name: sessionStorage.getItem("langType") == "en-us" ? "bank" : "银行",
          id: 3,
        },
        {
          name:
            sessionStorage.getItem("langType") == "en-us"
              ? "Insurance company"
              : "保险公司",
          id: 4,
        },
        {
          name:
            sessionStorage.getItem("langType") == "en-us"
              ? "Other institutions"
              : "其他机构",
          id: 9,
        },
      ],
      title:
        sessionStorage.getItem("langType") == "en-us"
          ? "Cooperative partner"
          : "合作伙伴",
      langTrue: sessionStorage.getItem("langType"),
      jianjieback:
        sessionStorage.getItem("langType") == "en-us"
          ? require("../assets/imgs/joinUs/newpartners.png")
          : require("../assets/imgs/joinUs/partners.png"),
      bankList: [
        // {
        //   img: require("@/assets/imgs/cooperation/bank-logo1.png"),
        //   name: "银行",
        // },
      ],
    };
  },
  computed: {
    costRequest() {
      return {
        organizationType: this.activeTab,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.getselectPartnerList();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
  },
  components: {
    Footer,
    Back,
    NoData,
  },
  mounted() {
    this.getselectPartnerList();
  },
  methods: {
    activeTabclick: function (val) {
      this.activeTab = val;
    },
    getselectPartnerList() {
      this.nodata = false;
      selectPartnerList({
        lang: sessionStorage.getItem("langType"),
        // organizationType: 3,
      }).then((res) => {
        let data = [];
        res.data.map((v) => {
          data.push({
            img: baseURL + v.logo,
            name: v.organizationName,
            val: v.content,
          });
        });
        this.bankList = data;
        if (!this.bankList.length) {
          this.nodata = true;
        }
      });
    },
    tanchuang: function (val) {
      console.log(val);
      this.tanchuangval = val;
      this.istrue = true;
    },
    tanchuangfalse: function () {
      this.istrue = false;
    },
  },
};
</script>
<style scoped lang="scss">
.router-banner {
  display: flex;
  height: 662px;
  background: url("../assets/imgs/cooperation/cooperation-banner.jpg") center
    center;
}
.joinUsDetails-content {
  width: 1200px;
  min-height: 741px;
  margin: 0 auto;
  padding-top: 30px;
  position: relative;
  .tab-item {
    color: #0072cb;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    margin-bottom: 45px;
  }

  .tab-item::after {
    content: "";
    display: block;
    transition: all 200ms ease-in-out 0s;
    cursor: pointer;
    height: 2px;
    background: #0072cb;
    margin: 0 auto;
    width: 0;
  }
  .tab-item:hover::after,
  .tab-item.active::after {
    width: 100%;
  }
  .tab-content {
    .tab-content-top {
      font-size: 24px;
      line-height: 1;
      display: flex;
      align-items: flex-end;
      margin-bottom: 60px;
      & > p {
        padding-bottom: 3px;
      }
      & > span {
        margin: 0 20px;
        height: 30px;
        width: 2px;
        background: #1e83d1;
        display: inline-block;
      }
    }
    .tab-btns {
      display: flex;
      li {
        // width: 90px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        margin-right: 40px;
        color: #444;
        padding: 5px 20px;
        &.active,
        &:hover {
          background: #eee;
        }
      }
    }
    .bank-list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      line-height: 1;
      width: 1200px;
      // margin-left: -20px;
      // padding-top: 48px;
      li {
        width: 203px;
        height: 140px;
        margin-right: 35px;
        margin-bottom: 40px;
        text-align: center;
        font-size: 16px;
        // margin-bottom: 10px;
        border-radius: 10px;
        border: 2px solid #eeeeee;
        cursor: pointer;
        & > p {
          margin-top: 30px;
          height: 50px;
          & > img {
            max-width: 45px;
          }
        }
        & > div {
          margin-top: 20px;
          width: 100%;
          overflow: hidden;
          height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 10px;
        }
      }
      li:hover {
        border: 2px solid #0072cb;
      }
    }
  }
}
.tanchuang {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .tanchuangval {
    width: 480px;
    min-height: 400px;
    background: white;
    position: relative;
    border-radius: 30px;
    .tanchuangvalback {
      position: absolute;
      right: 20px;
      top: 10px;
      color: white;
      font-size: 35px;
      cursor: pointer;
    }
    .tanchuangvalback::before {
      content: "×";
    }
    .tanchuangvaltop {
      width: 100%;
      height: 117px;
      background: #0072cb;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }
    .tanchuangvaliicon {
      position: absolute;
      width: 110px;
      height: 110px;
      left: 50%;
      margin-left: -55px;
      top: 40px;
      background: white;
      border-radius: 50%;
      box-shadow: 0 0 8px #8cc5fe;
      display: flex;
      justify-content: center;
      align-items: center;
      .tanchuangvaliiconimg {
        max-width: 45px;
      }
    }
    .tanchuangvaltitle {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 44px;
      font-size: 20px;
      color: #444444;
    }
    .tanchuangvaltvalue {
      text-align: justify;
      text-indent: 2em;
      width: 100%;
      color: #444444;
      margin-top: 10px;
      font-size: 14px;
      padding: 0 45px 30px 45px;
      line-height: 1.75em;
    }
  }
}
</style>
